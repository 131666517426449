const getters = {
  getUserData(state) {
    return state.user_detail;
  },
  getDrawer(state) {
    return state.drawer;
  },
  getSettingsValue(state) {
    return state.settings;
  },
  getMessagesValue(state) {
    return state.messages;
  },
  getSchedulesValue(state) {
    return state.schedules;
  },
  getLoginStatus(state) {
    return state.loginFailure;
  },
};

export default getters;
