import { APIdoLogin, APIdoLogout } from "@/api/authentication/api";
import {
  APIdoGetUser,
  APIdoGetSettings,
  APIdoGetMessages,
  APIdoGetSchedules,
} from "@/api/common/api";
import router from "@/router";
import {
  SET_LOGIN_FAILURE,
  SET_USER_DETAIL,
  SET_NOTIFICATION_STATE,
  SET_SETTINGS_VALUES,
  SET_MESSAGES_VALUES,
  SET_SCHEDULES_VALUES,
} from "./mutation_types";

const actions = {
  doLogin({ commit }, { username, password }) {
    APIdoLogin(username, password)
      .then((response) => {
        const user = {
          token: response.data.token,
        };
        if (response.data.success) {
          localStorage.setItem("user", JSON.stringify(user));
          commit(SET_LOGIN_FAILURE, {
            value: false,
            message: "",
          });
        }
        APIdoGetUser()
          .then((response1) => {
            commit(SET_USER_DETAIL, {
              user_detail: response1.data,
            });
            router.push("/app/dashboard");
          })
          .catch(() => {});

        commit(SET_NOTIFICATION_STATE, {
          isVisible: true,
          status: true,
          message: "You have succesfully logged in",
        });
        setTimeout(() => {
          commit(SET_NOTIFICATION_STATE, {
            isVisible: false,
            status: true,
            message: "You have succesfully logged in",
          });
        }, 3000);
      })
      .catch((error) => {
        commit(SET_NOTIFICATION_STATE, {
          isVisible: true,
          status: false,
          message: "Login failed",
        });
        commit(SET_LOGIN_FAILURE, {
          value: true,
          message: error.response.data.message,
        });
      });
  },

  doChangeLoginFailueMsg({ commit }) {
    commit(SET_LOGIN_FAILURE, {
      value: false,
      message: "",
    });
  },

  doGetUsers({ commit }) {
    APIdoGetUser()
      .then((response1) => {
        commit(SET_USER_DETAIL, {
          user_detail: response1.data,
        });
      })
      .catch(() => {});
  },

  doGetSettings({ commit }) {
    APIdoGetSettings()
      .then((response) => {
        commit(SET_SETTINGS_VALUES, response.data);
      })
      .catch(() => {});
  },
  doGetMessages({ commit }) {
    APIdoGetMessages()
      .then((response) => {
        commit(SET_MESSAGES_VALUES, response.data);
      })
      .catch(() => {});
  },

  doGetSchedules({ commit }) {
    APIdoGetSchedules()
      .then((response) => {
        commit(SET_SCHEDULES_VALUES, response.data);
      })
      .catch(() => {});
  },
};

export default actions;
