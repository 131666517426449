import Vue from "vue";
import Vuex from "vuex";
import mutations from "./mutations";
import actions from "./actions.js";

import state from "./state";
import getters from "./getters";
import { Store } from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Store({
  state,
  mutations,
  getters,
  actions,
  plugins: [createPersistedState()],
});
