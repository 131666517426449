const state = {
  loginFailure: {},
  user_detail: {},
  notification: {},
  drawer: null,
  schedules: {},
  messages: {},
};

export default state;
