<template>
  <router-view />
</template>

<script>
import "./sass/custom.scss";
export default {
  name: "App",
};
</script>
