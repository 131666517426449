import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);
export default new Router({
  // mode: "hash",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/app/",
      component: () => import("@/views/dashboard/Index"),
      redirect: "dashboard",
      children: [
        // Dashboard
        {
          name: "Dashboard",
          path: "dashboard",
          icon: "mdi-view-dashboard",
          component: () => import("@/views/dashboard/Dashboard"),
        },
        // Pages
        {
          name: "Users",
          path: "pages/users",
          icon: "mdi-account-multiple",
          component: () => import("@/views/dashboard/pages/Users"),
        },
        {
          name: "Profile",
          path: "pages/profile",
          icon: "mdi-account-multiple",
          component: () => import("@/views/dashboard/pages/Profile"),
        },
        {
          name: "Log",
          path: "pages/logs",
          icon: "mdi-alert-circle",
          component: () => import("@/views/dashboard/pages/Logs"),
        },
        {
          name: "Settings",
          path: "pages/settings",
          icon: "mdi-cog-outline",
          component: () => import("@/views/dashboard/pages/Settings"),
        },
        {
          name: "SMS Messages",
          path: "pages/sms",
          icon: "mdi-email-outline",
          component: () => import("@/views/dashboard/pages/Sms"),
        },
        {
          name: "SMS Schedules",
          path: "pages/schedule",
          icon: "mdi-calendar-outline",
          component: () => import("@/views/dashboard/pages/Schedule"),
        },
      ],
    },
    {
      name: "Login",
      path: "/",
      icon: "mdi-view-dashboard",
      component: () => import("@/views/auth/Login"),
    },
  ],
});
