import {
  SET_DRAWER,
  SET_LOGIN_FAILURE,
  SET_USER_DETAIL,
  SET_NOTIFICATION_STATE,
  SET_SETTINGS_VALUES,
  SET_MESSAGES_VALUES,
  SET_SCHEDULES_VALUES,
} from "./mutation_types";

const mutations = {
  [SET_DRAWER](state, payload) {
    state.drawer = payload;
  },
  [SET_LOGIN_FAILURE](state, payload) {
    state.loginFailure = payload;
  },
  [SET_USER_DETAIL](state, payload) {
    state.user_detail = payload.user_detail;
  },
  [SET_NOTIFICATION_STATE](state, payload) {
    state.notification = payload;
  },
  [SET_SETTINGS_VALUES](state, payload) {
    state.settings = payload;
  },
  [SET_MESSAGES_VALUES](state, payload) {
    state.messages = payload;
  },
  [SET_SCHEDULES_VALUES](state, payload) {
    state.schedules = payload;
  },
};

export default mutations;
