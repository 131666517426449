import axios from "axios";
import { authHeader } from "@/helpers/authentication-header";

// =========== LOGIN ===============
const API_ENDPOINT = "https://gmbsms.org.uk/api/";
// const API_ENDPOINT = "https://netrixapi.co.uk/api/";

export function APIdoLogin(username, password) {
  console.log(username, password);
  return axios.post(
    API_ENDPOINT + "login",
    {
      username: username,
      password: password,
    },
    {}
  );
}

export function APIdoCheckActive() {
  return axios.post(
    API_ENDPOINT + "active",
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        ...authHeader(),
      },
    }
  );
}

export function APIdoLogout({ action = "logout" }) {
  return axios.post(API_ENDPOINT + "login", null, {
    params: {
      action,
    },
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Request-Methods": "GET, POST, OPTIONS",
      "Access-Control-Request-Headers": "Content-Type, Authorization, Accept",
      ...authHeader(),
    },
  });
}
